<template>
  <div>
    <div class="flex mb-4">
      <div class="ml-auto">
        <edu-binary-switch
          flex-justify="end"
          class="text-right items-end"
          :label="$t('modo-visualizacao')"
          v-model="viewMode"
          left-text="Cartões"
          right-text="Tabela"
        />
      </div>
    </div>
    <transition :duration="500" mode="out-in" tag="div" name="slide-fade">
      <certified-user-grid-card-view v-if="layout==='cards'" :service="service"/>

      <Grid v-if="layout==='table'"
            :service="service"
            route_name="certified_user"
            route_grid_path="/grid">
        <template v-slot:gridThead>
          <vs-th key="id" sort-key="id">ID</vs-th>
          <vs-th key="name" sort-key="name">{{ $t('name') }}</vs-th>
        </template>
        <template v-slot:gridData="props">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in props.data">
            <vs-td width="5%" key="id">
              <div v-html-safe="tr.id"></div>
            </vs-td>
            <vs-td width="75%" key="name">
              <div v-html-safe="tr.certified_commit.certified_name"></div>
            </vs-td>
            <vs-td width="15%">
                <feather-icon
                  v-permission="'certified_user.show'"
                  icon="DownloadIcon"
                  svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-success"
                  @click="donwloadCertified(props.data[indextr])"
                  v-tooltip.top-left="{
                    content: 'Baixar Certificado',
                    delay: { show: 500, hide: 100 },
                  }"/>
              </vs-td>
          </vs-tr>
        </template>
      </Grid>
    </transition>
  </div>
</template>

<script>
import CertifiedUserService from '@/services/api/CertifiedUserService'
import FileDownload from 'js-file-download'
import { mimeType } from '@/util/Util'

import EduBinarySwitch from '@/components/EduBinarySwitch.vue'
import CertifiedUserGridCardView from './CertifiedUserGridCardView.vue'

export default {
  components: {
    EduBinarySwitch,
    CertifiedUserGridCardView,
  },
  data: () => ({
    viewMode: false, // true = 'table', false = 'cards'
    selected: [],
    service: null
  }),
  computed: {
    layout() {
      return this.viewMode ? 'table' : 'cards'
    }
  },
  methods: {
    donwloadCertified(certUser) {
      const certUserId = certUser.id
      const fileName = _.get(certUser, 'file_certified.file_name')
      this.service.downloadCertified(certUserId).then((data) => {
        this.$vs.loading.close()
        FileDownload(data, fileName, mimeType('pdf'))
      }
      )
    }
  },
  beforeMount() {
    this.service = CertifiedUserService.build(this.$vs)
  }
}
</script>
