<template>
  <infinite-grid
    :style="infiniteGridStyleInjection"
    order-column="id"
    direction="desc"
    class="infinite-grid"
    method="certifiedUserGrid"
    ref="grid"
    :service="service">

    <template v-slot:item="data">
      <vx-card
        class="w-auto shadow-drop border-solid border-grey border playful"
        headerStyle="w-full text-left font-bold text-white pb-4">
        <template v-slot:header>
          <div class="pt-2 flex items-center justify-between">
            <label class="_class-card-title">{{ _.get(data, 'item.certified_commit.certified_name')}}</label>
            <feather-icon
              v-if="false"
              svgClasses="w-5 h-5 2xl:w-4 2xl:h-4"
              class="cursor-pointer"
              icon="MoreVerticalIcon"/>
          </div>
        </template>
        <div class="flex flex-col w-full gap-3 justify-center">
          <p v-tooltip.auto="{
              content: _.get(data, 'item.certified_commit.institution_name'),
              delay: {
                show: 500, hide: 500
              }
            }"
            class="_class-card-description">
            {{$t('certified_user.emitted_from')}}: &nbsp;
            {{ _.get(data, 'item.certified_commit.institution_name') }}
          </p>
          <div class="flex justify-center">
            <vs-button @click="donwloadCertified(data.item)">{{ $t('download') }}</vs-button>
          </div>
        </div>
        <template v-slot:footer>
          <div class="w-full flex flex-shrink-0 flex-wrap justify-between items-center">
            <label  class="text-sm">
             <!-- {{$t('certified_user.institution_name')}}:{{_.get(data, 'institution_name')}}-->
             {{$t('certified_user.validation_code')}}: &nbsp;{{_.get(data, 'item.validation_cod')}}
            </label>
          </div>
        </template>
      </vx-card>
    </template>
  </infinite-grid>
</template>

<script>

import FileDownload from 'js-file-download'
import { mimeType } from '@/util/Util'

import infiniteGrid from '@/components/infiniteGrid.vue'
import CertifiedUserService from '@/services/api/CertifiedUserService'


export default {
  components: {
    infiniteGrid,
  },
  props: {
    service: {
      default: null,
      type: Object
    }
  },
  data: () => ({
    columns: null,
    classCount: 0,
  }),
  computed: {
    infiniteGridStyleInjection() {
      return `--infinite-grid-cols: ${this.columns || this.gridCols()};`
    }
  },
  methods: {
    donwloadCertified(certUser) {
      const certUserId = certUser.id
      const fileName = _.get(certUser, 'file_certified.file_name')
      this.service.downloadCertified(certUserId).then((data) => {
        this.$vs.loading.close()
        FileDownload(data, fileName, mimeType('pdf'))
      }
      )
    },
    gridCols() {
      const colLimit = this.$utils.reactive.breakpointColsMax()
      const colMinimum = this.$utils.reactive.breakpointColsMin()
      return this.$utils.reactive.colsForTotal(this.classCount, colLimit, colMinimum)
    },
    recomputeGridCols() {
      this.columns = this.gridCols()
    },
  },
  beforeMount() {
    this.service = CertifiedUserService.build(this.$vs)
    window.addEventListener('resize', this.recomputeGridCols)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.recomputeGridCols)
  }
}
</script>

<style>
  ._class-card-title, ._class-card-description {
    @apply overflow-hidden;
    @apply text-justify;
    @apply break-words;
  }
  ._class-card-title {
    min-height: 1.5rem;
    max-height: 1.5rem;
  }
  ._class-card-description {
    min-height: 5rem;
    max-height: 5rem;
  }
</style>

<style lang="scss" scoped>
.infinite-grid /deep/ ._infinite-grid {
  grid-template-columns: repeat(var(--infinite-grid-cols), minmax(0, 1fr));
}

.playful /deep/ .vx-card__header {
  padding-top: 0.5rem;
  //background: padding-box linear-gradient(218deg,#00eef6,#00dde6 50%,#31acff);
  background: padding-box  linear-gradient(218deg, rgba(var(--vs-primary), 1), rgba(var(--vs-primary), .7)) !important;
  border-radius: 0.4rem;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.playful /deep/ .vx-card__footer {
  border-top: 1px solid #b8c2cc80;
  padding: 0.25rem 0.50rem 0.50rem 0.50rem;
}

.playful /deep/ .vx-card__body {
  padding: 1rem;
}
</style>
