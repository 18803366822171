var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "_edu-binary-switch",
        "flex",
        "flex-wrap",
        "flex-row",
        "items-center",
        "justify-" + _vm.flexJustify,
      ],
    },
    [
      _vm.label
        ? _c("label", { staticClass: "flex-grow font-medium w-full" }, [
            _vm._v(_vm._s(_vm.label)),
          ])
        : _vm._e(),
      _vm.leftText
        ? _c(
            "label",
            {
              class: [
                "pr-2",
                "text-sm",
                "sm:text-base",
                { "text-primary": _vm.onLeft },
              ],
            },
            [_vm._v(" " + _vm._s(_vm.leftText) + " ")]
          )
        : _vm._e(),
      _c(
        _vm.component,
        _vm._g(
          _vm._b(
            {
              tag: "component",
              on: {
                input: function ($event) {
                  _vm.valueMirror = $event
                },
              },
            },
            "component",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
      _vm.rightText
        ? _c(
            "label",
            {
              class: [
                "pl-2",
                "text-sm",
                "sm:text-base",
                { "text-primary": _vm.onRight },
              ],
            },
            [_vm._v(" " + _vm._s(_vm.rightText) + " ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }