import Rest from '../Rest'

export default class CertifiedUserService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/certified_user'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  downloadCertified(certifiedEmitUserId: Number) {
    return this.getBlob(`${certifiedEmitUserId}/download`).then(
      data => data
    )
  }

  validateCertified(code: String) {
    return this.get(`${code}/validate`)
  }

  certifiedUserGrid(path, data) {
    return this.get(`/grid`, data)
  }


}
