var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("infinite-grid", {
    ref: "grid",
    staticClass: "infinite-grid",
    style: _vm.infiniteGridStyleInjection,
    attrs: {
      "order-column": "id",
      direction: "desc",
      method: "certifiedUserGrid",
      service: _vm.service,
    },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function (data) {
          return [
            _c(
              "vx-card",
              {
                staticClass:
                  "w-auto shadow-drop border-solid border-grey border playful",
                attrs: {
                  headerStyle: "w-full text-left font-bold text-white pb-4",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "pt-2 flex items-center justify-between",
                            },
                            [
                              _c(
                                "label",
                                { staticClass: "_class-card-title" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._.get(
                                        data,
                                        "item.certified_commit.certified_name"
                                      )
                                    )
                                  ),
                                ]
                              ),
                              false
                                ? _c("feather-icon", {
                                    staticClass: "cursor-pointer",
                                    attrs: {
                                      svgClasses: "w-5 h-5 2xl:w-4 2xl:h-4",
                                      icon: "MoreVerticalIcon",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "footer",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "w-full flex flex-shrink-0 flex-wrap justify-between items-center",
                            },
                            [
                              _c("label", { staticClass: "text-sm" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("certified_user.validation_code")
                                    ) +
                                    ":  " +
                                    _vm._s(
                                      _vm._.get(data, "item.validation_cod")
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              },
              [
                _c(
                  "div",
                  { staticClass: "flex flex-col w-full gap-3 justify-center" },
                  [
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip.auto",
                            value: {
                              content: _vm._.get(
                                data,
                                "item.certified_commit.institution_name"
                              ),
                              delay: {
                                show: 500,
                                hide: 500,
                              },
                            },
                            expression:
                              "{\n            content: _.get(data, 'item.certified_commit.institution_name'),\n            delay: {\n              show: 500, hide: 500\n            }\n          }",
                            modifiers: { auto: true },
                          },
                        ],
                        staticClass: "_class-card-description",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("certified_user.emitted_from")) +
                            ":   " +
                            _vm._s(
                              _vm._.get(
                                data,
                                "item.certified_commit.institution_name"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "flex justify-center" },
                      [
                        _c(
                          "vs-button",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.donwloadCertified(data.item)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("download")))]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }