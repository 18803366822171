<template>
  <div :class="['_edu-binary-switch', 'flex', 'flex-wrap', 'flex-row', 'items-center', `justify-${flexJustify}`]">
    <!-- Main Label -->
    <label class="flex-grow font-medium w-full" v-if="label">{{label}}</label>

    <!-- Left Label -->
    <label
      v-if="leftText"
      :class="['pr-2', 'text-sm', 'sm:text-base', {'text-primary': onLeft}]"
    >
      {{ leftText }}
    </label>

    <!-- The switch component itself -->
    <component
      @input="valueMirror = $event"
      :is="component"
      v-bind="$attrs"
      v-on="$listeners"
    />

    <!-- Right Label -->
    <label
      v-if="rightText"
      :class="['pl-2', 'text-sm', 'sm:text-base', {'text-primary': onRight}]"
    >
      {{ rightText }}
    </label>
  
  </div>
</template>

<script>
export default {
  computed: {
    onLeft() {
      return this.valueMirror === false
    },
    onRight() {
      return this.valueMirror === true
    }
  },
  data: () => ({
    valueMirror: false
  }),
  props: {
    flexJustify: {
      default: 'center',
      type: String
    },
    leftText: {
      default: null,
      type: String
    },
    rightText: {
      default: null,
      type: String
    },
    label: {
      default: null,
      type: String
    },
    component: {
      default: 'vs-switch',
      type: String
    }
  }
}
</script>

<style>

</style>
